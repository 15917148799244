import Cookies from "js-cookie";

const setUser = (data) => {
  Cookies.set("oms_token", data.access_token);
  Cookies.set("exp_token", data.exp);
};

const getUser = () => {
  const token = Cookies.get("oms_token");
  const parseJwt = (token) => {
    try {
      return JSON.parse(window.atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const tokenData = parseJwt(token);

  const roles = tokenData?.roles;
  const role = tokenData?.roles[0];
  const division = tokenData?.division;
  const user = tokenData?.data.userName;
  const isBlur = tokenData?.is_blur;
  return { roles, role, division, user, tokenData, isBlur };
};

export { setUser, getUser };
