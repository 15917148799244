export const LANGUAGES = [
  { code: "", flagID :"", label: "All", currencyCode: "IDR" },
  { code: "id-ID", flagID :"ID", label: "Indonesia", currencyCode: "IDR" },
  { code: "en-US", flagID :"GB", label: "English", currencyCode: "USD" },
  { code: "ar-SA", flagID :"SA", label: "Arabic", currencyCode: "SAR" },
  { code: "ar-AE", flagID :"AE", label: "عربي", currencyCode: "AED" },
  { code: "kk-KZ", flagID :"KZ", label: "Kazakh", currencyCode: "KZT" },
  { code: "ru-RU", flagID :"RU", label: "Rusia", currencyCode: "KZT" },
  { code: "th-TH", flagID :"TH", label: "Thailand", currencyCode: "TBH" },
  { code: "procimart", flagID :"procimart", label: "ProcimartID", currencyCode: "IDR" },
  { code: "id", flagID :"id", label: "OciexclusiveID", currencyCode: "IDR" },
].filter((l) => ["", "id", "procimart"].includes(l.code));