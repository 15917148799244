// import { getUser } from "helpers/parseJWT";
// const params = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop),
// });

import { CloseOutlined, FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { Box, Fade, Modal } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LangcodeSwitch from "./LangcodeSwitch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: '80dvw',
  maxHeight: 600,
  bgcolor: "background.paper",
  border: "2px solid lightgray",
  boxShadow: 24,
  borderRadius: 2,
};

const FilterPurchasing = ({
  filterToko,
  filterSorting,
  idSo,
  toko = "all",
  setToko,
  auto,
  setAuto,
  date,
  setDate,
  setUpdate,
  actionSubmit,
  setIdSo,
  setIsOpen,
  isOpen
}) => {
  const { t } = useTranslation();
  const maxDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];

  const handleChangeDate = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
  };

  return (
    <>
      <div className="flex items-center gap-2 bg-white rounded-md my-2 px-4 py-3">
        <span>Filter :</span>
        <div className="px-4 gap-2 flex">
          <div className="filter-idso gap-4 flex">
            <input
              type="text"
              placeholder={t("search by order id")}
              className="border rounded-md px-2 py-1 ml-1"
              onChange={(e) => setIdSo(e.target.value)}
            // onKeyDown={searchOrderById}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex gap-2">
              <input
                type="text"
                placeholder={t("Start date")}
                name="start"
                value={date?.start}
                max={maxDate}
                onChange={handleChangeDate}
                className="py-1 px-2 rounded-md w-32 border border-gray-200 focus:outline-blue cursor-pointer"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
              <input
                type="text"
                name="end"
                className="py-1 px-2 rounded-md border border-gray-200 w-32 focus:outline-blue cursor-pointer"
                placeholder={t("End date")}
                value={date?.end}
                max={maxDate}
                onChange={handleChangeDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
            </div>
          </div>
          <div className="flex items-center text-sm space-x-3">
            <div className="flex items-center space-x-3">
              <p>{t("Sort order")}:</p>
              <select
                name="sorting"
                value={date?.sorting}
                onChange={handleChangeDate}
                className="border border-gray-300 p-1 rounded-md focus-within:outline-blue"
              >
                {/* <option value="" disabled>
              Select Sorting
            </option> */}
                <option value="asc">{t("Oldest")}</option>
                <option value="desc">{t("Latest")}</option>
              </select>
            </div>
          </div>
          <div className="flex items-center text-sm space-x-3 px-4">
            <div className="flex items-center ">
              {/* <p>{t("Country")}:</p> */}
              <LangcodeSwitch />
            </div>
          </div>
          <div>
            <button
              className="w-full text-xs px-6 rounded-md py-2 text-white cursor-pointer bg-blue-500 hover:bg-blue-600"
              onClick={e => actionSubmit(idSo, toko, auto)}>
              {t("Filter")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPurchasing;
