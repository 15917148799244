export const generateRandomString = (length = 10) => {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}
	return result;
};

export const objToSearchParam = (obj) => Object.entries(obj)
	.filter(([, val]) => val) // Remove falsy values
	.map(([key, val]) => key + "=" + val)
	.join('&');
