import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import SalesTable from 'components/ManagementSales/SalesTarget/SalesTable';
import { getSalesData } from 'service/api';
import FilterSalesTarget from 'components/ManagementSales/SalesTarget/FilterSalesTarget';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import { useRecoilState } from 'recoil';
import { getLangcode } from 'atom';

const SalesTarget = () => {
  const [salesData, setSalesData] = useState([]);
  const [salesDataNew, setsalesDataNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [langcodes, setLangcodes] = useRecoilState(getLangcode);

    

  const fetchSalesData = async () => {
    setIsLoading(true);
    const langcode = langcodes !== '' ? `?langcode=${langcodes}` : '';
    const params = langcode

    const data = await getSalesData(params);

    if (data?.status === 200) {
      setSalesData(data.data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSalesData();
  }, [update, langcodes]);

  useEffect(() => {
    setSalesData(salesDataNew);
  }, [salesDataNew, isFiltered]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <p className="my-4 bg-white w-28 p-2 rounded-md cursor-pointer text-center">
        Sales Target
      </p>
      {!isLoading && (
        <FilterSalesTarget
          setIsLoading={setIsLoading}
          setIsFiltered={setIsFiltered}
          setsalesDataNew={setsalesDataNew}
        />)}

      <SalesTable
        isLoading={isLoading}
        setUpdate={setUpdate}
        salesData={salesData}
      />
    </Layout>
  );
};

export default SalesTarget;
