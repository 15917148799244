import { useEffect, useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Fade, Modal } from "@mui/material";
import { LANGUAGES } from "helpers/constants";
import Cookies from "js-cookie";
import { getLangcode } from "atom";
import { useRecoilState } from "recoil";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  maxWidth: '80dvw',
  maxHeight: 600,
  bgcolor: "background.paper",
  border: "2px solid lightgray",
  boxShadow: 24,
  borderRadius: 2,
};

const LangcodeSwitch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelectLanguage] = useState('ID')
  const [langcodes, setLangcodes] = useRecoilState(getLangcode);

  const handleSelect = (language, flag) => {
    setIsOpen(false);
    setSelectLanguage(language.flagID)
    setLangcodes(language.flagID.toLowerCase());
  };

  return (
    <div className="language-switcher flex items-center">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="gap-1 flex items-center"
      >
        {/* <img className="w-20 flex-shrink-0 rounded overflow-hidden"
                        alt="country-icon"
                        src={``}/> */}
        <img
          // alt={language.label}
          className="h-8"
          src={`${langcodes === "procimart" ? "https://s3x.ocistok.com/ocistok/content/foto/id-logo-procimart_cropped.webp" : 
            langcodes === "id" ? "/logo-ocistok.svg" : `/all-logo.svg`}`} />
        <LanguageIcon className="text-black" fontSize="small" />
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style} className="overflow-y-auto max-h-[350px] grid">
            {LANGUAGES.map((language) => (
              <div
                key={language.code}
                onClick={() => handleSelect(language, language.flagID)}
                className="cursor-pointer gap-3 flex justify-between hover:bg-gray-50 p-4"
              >
                <img
                  alt={language.label}
                  className="h-8 object-cover"
                  src={`${language.flagID === "procimart" ? "https://s3x.ocistok.com/ocistok/content/foto/id-logo-procimart_cropped.webp" : 
                    language.flagID === "id" ? "/logo-ocistok.svg" : `/all-logo.svg`}`} />
                <div>{language.label}</div>
              </div>
            ))}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default LangcodeSwitch;
