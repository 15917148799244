import MaterialTable from "material-table";
import { CircularProgress, Box, Tooltip } from "@mui/material";
import tableIcons from "helpers/materialTableIcons";
import OptionModal from "./OptionModal";
import CancelModal from "./CancelModal";
import Notes from "components/General/Notes";
import { formatDateDDMMYY } from "helpers/ConvertTime";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

const CheckVolumeTable = ({
  dataTable,
  isLoading,
  setUpdate,
  productCategoryData,
  materialData,
}) => {
  const { t } = useTranslation();

  const download = (logos) => {
    if (logos?.length) {
      const a = logos.map((logo) => "gambar[]=" + logo);
      const join = a.join("&");
      return "https://control-panel.ocistok.com/download.php?" + join;
    }
  };

  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        padding: "10px",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const rows = dataTable?.map((prev, i) => {
    return { ...prev, id: i + 1 };
  });

  return (
    <>
      {renderLoading}
      <MaterialTable
        localization={{
          toolbar: {
            searchPlaceholder: t("Search Table"),
          },
        }}
        isLoading={isLoading}
        icons={tableIcons}
        title={t("Check Details Table")}
        columns={[
          {
            title: t("ID Request"),
            field: "id",
            cellStyle: {
              minWidth: 50,
              maxWidth: 150,
            },
            render: (rowData) => (
              <div className="flex items-center gap-3">
                <p className="text-sm">{rowData.id}</p>
                <div className="grid gap-1">
                  {rowData.is_oem && (
                    <span className=" text-white bg-red-500 px-2  rounded">
                      ODM
                    </span>
                  )}
                  {
                    rowData.source == "procimart" ? (
                      <div className="w-20">
                        <img
                          alt="language"
                          className="border border-orange-500 w-fit p-1 rounded-md"
                          src={"https://s3x.ocistok.com/ocistok/content/foto/id-logo-procimart_cropped.webp"} />
                      </div>
                    ) : (
                      <div className="w-20">
                        <img
                          alt="language"
                          className="border border-orange-500 w-fit rounded-md"
                          src={"/logo-ocistok.svg"} />
                      </div>
                    )
                  }
                </div>
              </div>
            ),
          },
          {
            title: t("Customer"),
            field: "customer.email" || "customer.name",
            render: (rowData) => (
              <div className="text-sm relative">
                <div>
                  {rowData?.customer?.email
                    ? rowData?.customer?.email
                    : rowData?.customer?.name}
                </div>
                {rowData?.is_big_order && (
                  <div className="inline-block task-status px-2 py-1 text-xs text-white text-center bg-green-500 rounded-md">
                    Big Order
                  </div>
                )}
                {rowData?.tag && (
                  <div className="inline-block px-2 py-1 text-xs rounded-md bg-gray-600 text-white">
                    {rowData.tag}
                  </div>
                )}
              </div>
            ),
          },
          {
            title: t("Link"),
            field: "link",
            cellStyle: {
              cellWidth: "50%",
            },
            render: (rowData) => (
              <Tooltip title={rowData.link} className="cursor-pointer">
                <div>
                  <a
                    href={rowData.link}
                    target="_blank"
                    className="text-sm w-40 line-clamp-1"
                    rel="noreferrer">
                    {rowData.link}
                  </a>
                  {rowData.is_priority && (
                    <span className=" text-white bg-yellow-500 text-xs px-2 py-1 rounded">
                      Priority
                    </span>
                  )}
                </div>
              </Tooltip>
            ),
          },
          {
            title: t("Product"),
            field: "product",
            render: (rowData) => (
              <Tooltip title={rowData.product} placement="top">
                <p className="text-sm line-clamp-2">
                  {rowData.product ? rowData.product : "-"}
                </p>
              </Tooltip>
            ),
          },
          {
            title: t("Note"),
            field: "note",
            render: (rowData) =>
              rowData.rollback ? (
                <>
                  <span className="bg-red-500 text-white px-2 text-sm rounded">
                    Rollback
                  </span>
                  <p className="text-sm w-60 line-clamp-4">
                    {JSON.parse(rowData.rollback).reason}
                  </p>
                  <p className="text-sm">
                    by {JSON.parse(rowData.rollback).user}
                  </p>
                </>
              ) : (
                <p className="text-sm">-</p>
              ),
          },
          {
            title: t("Sales"),
            field: "sales",
            render: (rowData) => (
              <p className="text-sm">{rowData.sales ? rowData.sales : "-"}</p>
            ),
          },
          {
            title: t("Logo"),
            field: "logo",
            render: (rowData) =>
              rowData.is_oem &&
              (rowData.logo?.length ? (
                <div>
                  <a
                    href={download(rowData.logo)}
                    className="px-2 py-1 rounded bg-gray-400 text-white">
                    Download
                  </a>
                </div>
              ) : (
                <div className="text-sm">no logo</div>
              )),
          },
          {
            title: t("Option"),
            field: "user",
            render: (rowData) => {
              return (
                <div className="space-y-2">
                  <OptionModal
                    id={rowData.id_request}
                    link={rowData.link}
                    boxValue={rowData.box}
                    setUpdate={setUpdate}
                    addData={{
                      email: rowData.customer.email
                        ? rowData.customer.email
                        : rowData.customer,
                      type: rowData.type,
                      store: rowData.store,
                    }}
                    imageProduct={rowData?.image}
                    productName={rowData.product}
                    productNameID={rowData.product_indo}
                    category={rowData.category}
                    dataQty={rowData.minQty}
                    num_iid={rowData.num_iid}
                    rowData={rowData}
                    productCategoryData={productCategoryData}
                    materialData={materialData}
                  />
                  <CancelModal
                    id={rowData.id_request}
                    link={rowData.link}
                    setUpdate={setUpdate}
                  />
                  <Notes
                    id={rowData.id_request}
                    setUpdate={setUpdate}
                    isComment={false}
                    isCheck={true}
                    commentStatus={rowData.comment}
                  />
                </div>
              );
            },
          },
          {
            title: t("Date"),
            field: "date",
            render: (rowData) => (
              <p className="text-sm text-center">
                {formatDateDDMMYY(rowData.date)}
              </p>
            ),
          },
        ]}
        data={rows}
        options={{
          // exportButton: true,
          pageSizeOptions: [20, 50, 100],
          pageSize: 50,
          rowStyle: {
            overflowWrap: "break-word",
          },
        }}
      />
    </>
  );
};

export default CheckVolumeTable;
