import { useState } from 'react';
import Layout from 'components/Layout';
import NewOrderTable from 'components/Purchasing/NewOrders/NewOrderTable';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportNewOrders } from 'service/api';
import exportFromJSON from 'export-from-json';
import { SubRoutesPurchasing as SUBROUTES } from 'components/Purchasing/SubRoutesPurchasing';
import swal from 'sweetalert';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LangcodeSwitch from 'components/General/LangcodeSwitch';

const REASON_OPTIONS = [
  { 
    text: "Booked beyond maximum quantity",
    value : "BOOKED_BEYOND_THE_MAX_QUANTITY"
  },
  { 
    text: "Api Error",
    value : "400"
  },
  { 
    text: "Purchase qty doesn't match packaging unit (qty/box)",
    value : "QUANTITY_UNMATCH_SELLUNIT_SCALE"
  },
  { 
    text: "Product is not exist",
    value : "SPEC_NO_EXIST"
  },
  { 
    text: "Not reaching MOQ",
    value : "BOOKED_LESS_THAN_LEAST_QUANTITY"
  },
  { 
    text: "There is an error on variants combination",
    value : "NOT_STATISFT_MIX_CONDITION"
  },
  { 
    text: "Shipping cost error",
    value : "FAIL_BIZ_CARGO_CARRIAGE_HAS_ERROR"
  },
  { 
    text: "Combination of variants are unsupported",
    value : "UNSUPPORT_MIX"
  },
  { 
    text: "Goods are not authorized",
    value : "CARGO_CANNOT_AUTH"
  },
  { 
    text: "Do not support online trade",
    value : "FAIL_BIZ_OFFER_NOT_SUPPORT_ONLINE_TRADE"
  },
  { 
    text: "Changes in shop promotion",
    value : "FAIL_BIZ_SELECTED_SHOP_PROMOTION_HAS_CHANGED"
  },
  { 
    text: "System Error",
    value : "FAIL_BIZ_TAOBAO_TP_EXCEPTION"
  },
]

const NewOrders = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation();

  const currentUrl = window.location.href;
  const path = currentUrl.split("?")[0];
  const pathfilter1688 = path + "?toko=1688";
  const pathfilter1688Error = path + "?toko=all&auto=true";
  const pathfilterTaobao = path + "?toko=taobao";
  const pathfilterAlibaba = path + "?toko=alibaba";
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const toko = query.get('toko') || "all";
  const auto = query.get('auto');
  const canceled = query.get('canceled');
  const oem = query.get("oem");

  const handleExport = async () => {
    setIsLoading(true);
    const response = await exportNewOrders();
    const exportType = exportFromJSON.types.xls;
    if (response.status === 200) {
      exportFromJSON({
        data: response.data,
        fileName: response.fileName,
        exportType,
      });
    } else {
      swal('Oops', response.message, 'error');
    }
    setIsLoading(false)
  };
  return (
    <Layout routes={SUBROUTES()} title="Purchasing">
      <div className="flex justify-between items-center">
        {/* <p className="my-4 bg-white w-32 p-2 rounded-md cursor-pointer text-center">
          New Orders
        </p> */}
        {/* <button
          disabled={isLoading}
          onClick={handleExport}
          className={`${isLoading ? 'bg-gray-300 cursor-default' : 'bg-blue-500 hover:bg-blue-600'} my-4 p-2 rounded-md text-white transiton-all duration-300 ml-auto`}>
            {isLoading? 'Exporting...' :
            <>
              <FileDownloadIcon fontSize="small" />
              <span>Export</span>
            </>
            }
        </button> */}
      </div>
      
      <div className='bg-white mt-2 rounded'>
        <div className= "flex items-center space-x-3 px-2">
          <span className='p-1'>{t("Marketplace")}:</span>
          <Link to={{search : "?toko=all"}}>
            <div className={`${isLoading ? 'bg-gray-300 cursor-default' : (toko === "all" || toko === null)? 'bg-orange-500' : 'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                All
            </div>
          </Link>
          <Link to={{search : "?toko=1688"}}>
            <div className={`${isLoading ? 'bg-gray-300 cursor-default' : toko === "1688" && (auto === 'false' || auto === null) ? "bg-orange-500" :'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                1688
            </div>
          </Link>

          <Link to={{search : "?toko=1688&auto=true"}}>
              <div className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer ${toko === "1688" && auto != null ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              1688 Error
              </div>
          </Link>

          <Link to={{search : "?toko=taobao"}}>
            <div className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko === "taobao" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              Taobao
            </div>
          </Link>

          <Link to={{search : "?toko=alibaba"}}>
            <div className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko === "alibaba" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              Alibaba
            </div>
          </Link>
        </div>
        <div className= "flex items-center space-x-3 px-2">
          <span className='p-1'>{t("Origin")}:</span>
          {
            (auto == null || auto == 'false') && (
              <>
                <Link to={{search : `?toko=${toko}`}}>
                  <div className={`${isLoading ? 'bg-gray-300 cursor-default' : (canceled == null && auto == null)? "bg-orange-500" :"bg-blue-500"} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                      All
                  </div>
                </Link>
                <Link to={{search : `?toko=${toko ? toko : "all"}&canceled=false&auto=false`}}>
                  <div className={`${isLoading ? 'bg-gray-300 cursor-default' : ( canceled == 'false' && auto == 'false')? "bg-orange-500" :"bg-blue-500"} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                      New
                  </div>
                </Link>
                <Link to={{search : `?toko=${toko ? toko : "all"}&canceled=true`}}>
                  <div className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${(canceled == 'true')? "bg-orange-500" : "bg-blue-500"} hover:bg-orange-400`}>
                    Canceled
                  </div>
                </Link>
                <Link to={{ search: `?toko=${toko ? toko : "all"}&oem=true&auto=false` }}>
                  <a
                    className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${
                      oem == "true" ? "bg-orange-500" : "bg-blue-500"
                    } hover:bg-orange-400`}>
                    Odm
                  </a>
                </Link>
                {/* <Link to={{ search: `?toko=${toko ? toko : "all"}&no_stock=true&auto=false` }}>
                  <a
                    className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${
                      oem == "true" ? "bg-orange-500" : "bg-blue-500"
                    } hover:bg-orange-400`}>
                    No stock
                  </a>
                </Link>
                <Link to={{ search: `?toko=${toko ? toko : "all"}&price_rising=true&auto=false` }}>
                  <a
                    className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${
                      oem == "true" ? "bg-orange-500" : "bg-blue-500"
                    } hover:bg-orange-400`}>
                    Price Rising
                  </a>
                </Link>
                <Link to={{ search: `?toko=${toko ? toko : "all"}&pass=true&auto=false` }}>
                  <a
                    className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${
                      oem == "true" ? "bg-orange-500" : "bg-blue-500"
                    } hover:bg-orange-400`}>
                    Pass
                  </a>
                </Link> */}
            </>
          )}
          {toko == "1688" && (auto != null) && (
            <div>
              <select name="" id="" 
                className='border px-2 py-1 mb-1'
                onChange={e => {
                  // console.log(e.target.value)
                  history.push(`?toko=${toko}&auto=${e.target.value}`)
                }}
              >
                <option value={"true"}>Select reason</option>
                {
                  REASON_OPTIONS.map(item => (
                    <option value={item.value}>{item.text}</option>
                  ))
                }
              </select>
            </div>
          )}
        </div>
      </div>
      <NewOrderTable />
    </Layout>
  );
};
export default NewOrders;
