import Layout from 'components/Layout';
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { Tooltip } from '@mui/material';
import CustomerTable from 'components/ManagementSales/OBE/CustomerTable';
import AddCustomer from 'components/ManagementSales/OBE/AddCustomer';
import PaginationFilter from 'components/ManagementSales/OBE/PaginationFilter';
import { getListOBE } from 'service/api';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import AssigneSales from 'components/ManagementSales/NewCustomer/AssigneSales';
import { getUser } from 'helpers/parseJWT';
import ImportDataExcel from 'components/ManagementSales/OBE/ImportDataExcel';
import { allowAll, allowAdmin, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import LangcodeSwitch from 'components/General/LangcodeSwitch';
import { useRecoilState } from 'recoil';
import { getLangcode } from 'atom';

const OBE = () => {
  const [customerData, setCustomerData] = useState([]);
  const [changeData, setChangeData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState('');
  const [select, setSelect] = useState('true');
  const [listSales, setListSales] = useState([]);
  const [selectedRowsNumber, setSelectedRowsNumber] = useState(0);
  const [showModalAssigneSales, setShowModalAssigneSales] = useState(false)
  const [showModalResponse, setShowModalResponse] = useState(false)
  const [openDoc, setOpenDoc] = useState(null);
  const [langcodes, setLangcodes] = useRecoilState(getLangcode);

    

  const fetchCustomerData = async (page, limit, select) => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page,
      limit: limit ? limit : 50,
      withsales: select,
      langcode: langcodes
    }).toString();
    const data = await getListOBE(page, limit, params);
    if (data?.status === 200) {
      if (data.data?.data.length === 0 && data.data?.errorMessage) {
        swal('Oops', `${data.data.errorMessage}`, 'error');
      }
      setCustomerData(data.data);
      setListSales(data.data?.listSales)
      setSelectedRowsNumber(0)
    } else {
      swal('error', `${data?.message}, 'error'`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCustomerData(page, limit, select);
  }, [changeData, limit, page, select, langcodes]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <Tooltip title="Refresh table" placement="right">
        <p
          className="my-4 bg-white w-12 p-2 rounded-md cursor-pointer text-center"
          onClick={() => {
            setLimit(50);
            setPage(1);
            setSelect('');
          }}>
          OBE
        </p>
      </Tooltip>
      <div className="flex items-center justify-between bg-white p-2 rounded-md my-3 md:overflow-x-hidden overflow-x-scroll w-full">
        <div className="flex space-x-3 items-center">
          <AddCustomer
            setChangeData={setChangeData}
            setLoading={setIsLoading}
            listSales={listSales}
          />
          {!isLoading && (
            <LangcodeSwitch />
          )}
        </div>
        <div className="flex items-center gap-2 ml-auto">
          {allowSupervisor.some((i) => getUser().roles?.includes(i)) &&
            <ImportDataExcel
              openDoc={openDoc}
              setOpenDoc={setOpenDoc}
              setChangeData={setChangeData}
            />
          }

          {(allowAdmin.some((i) => getUser().roles?.includes(i)) && !['obe', 'ae'].some((i) => getUser().division?.includes(i))) &&
            <AssigneSales
              dataSales={listSales}
              setChangeData={setChangeData}
              showModalAssigneSales={showModalAssigneSales}
              setShowModalAssigneSales={setShowModalAssigneSales}
              showModalResponse={showModalResponse}
              setShowModalResponse={setShowModalResponse}
            />
          }
        </div>
      </div>
      <div className="flex items-center justify-between bg-white p-2 rounded-md my-3 md:overflow-x-hidden overflow-x-scroll w-full">
        {customerData.totalData && (
          <div>
            <p className="md:text-sm text-xs font-semibold line-clamp-1">
              Showing {customerData.dataInPage} data of{' '}
              {customerData.totalData}
            </p>
          </div>
        )}
        <div className="ml-auto">
          <PaginationFilter
            page={page}
            setPage={setPage}
            setLimit={setLimit}
            totalPage={customerData?.totalPage}
            listSales={listSales}
            select={select}
            setSelect={setSelect}
          />
        </div>
      </div>
      <CustomerTable
        customerData={customerData}
        isLoading={isLoading}
        setChangeData={setChangeData}
        setLoading={setIsLoading}
        selectedRowsNumber={selectedRowsNumber}
        setSelectedRowsNumber={setSelectedRowsNumber}
      />
    </Layout>
  );
};

export default OBE;
